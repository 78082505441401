import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import config from '../config';

import app from './slices/app';
import auth from './slices/auth';
import featureInvites from './slices/features';
import coaches from './slices/coaches';
import courses from './slices/courses';
import communities from './slices/communities';
import countries from './slices/countries';
import channels from './slices/channels';
import events from './slices/events';
import homeworkSchedule from './slices/homeworkSchedule';
import tracks from './slices/tracks';
import services from './slices/services';
import chats from './slices/chats';
import liveEvent from './slices/live';
import appointments from './slices/appointment';
import availability from './slices/availability';
import journals from './slices/journals';
import coaching from './slices/coaching';
import reflections from './slices/reflections';

const combinedReducers = combineReducers({
  app,
  channels,
  tracks,
  homeworkSchedule,
  journals,
  chats,
  coaches,
  events,
  courses,
  communities,
  liveEvent,
  countries,
  services,
  featureInvites,
  appointments,
  availability,
  auth,
  coaching,
  reflections,
});

const reducer = (state, action) => {
  let nextState;
  switch (action.type) {
    case HYDRATE:
      nextState = {
        ...state,
        ...action.payload,
      };
      if (state.auth.user && !nextState.auth.user) {
        nextState.auth = state.auth;
      }
      // On path change, firebase auth listener does not fire but the redux state is reset causing auth loading state to be always true if no user is logged in
      if (!state.auth.authLoading && nextState.auth.authLoading) {
        nextState.auth.authLoading = state.auth.authLoading;
      }
      if (state.countries.allCountries && !nextState.countries.allCountries) {
        nextState.countries = state.countries;
      }
      if (state.courses.list && !nextState.courses.list) {
        nextState.courses.list = state.courses.list;
      }
      if (state.courses.selectedCourse && !nextState.courses.selectedCourse) {
        nextState.courses.selectedCourse = state.courses.selectedCourse;
      }
      if (state.events.list && !nextState.events.list) {
        nextState.events.list = state.events.list;
      }
      if (state.events.selectedEvent && !nextState.events.selectedEvent) {
        nextState.events.selectedEvent = state.events.selectedEvent;
      }
      if (
        state.communities.list &&
        !nextState.communities.list &&
        nextState.communities.list.length === 0 &&
        state.communities.list.length > 0
      ) {
        nextState.communities.list = state.communities.list;
      }
      if (
        state.communities.selectedCommunity &&
        !nextState.communities.selectedCommunity
      ) {
        nextState.communities.selectedCommunity =
          state.communities.selectedCommunity;
      }
      if (
        (state.liveEvent.editLiveEvent && !nextState.liveEvent.editLiveEvent) ||
        (state.liveEvent.selectedLiveEvent &&
          !nextState.liveEvent.selectedLiveEvent)
      ) {
        nextState.liveEvent = state.liveEvent;
      }
      if (
        (state.services.services && !nextState.services.services) ||
        (state.services.serviceSelected && !nextState.services.serviceSelected)
      ) {
        nextState.services = state.services;
      }
      return nextState;
    default:
      return combinedReducers(state, action);
  }
};

const makeStore = () =>
  configureStore({
    reducer,
    devTools: config.environment === 'development',
    middleware: [thunkMiddleware],
  });

export const reduxWrapper = createWrapper(makeStore);
