import TAGS from '../data/upload-tags.json';
import TOPICS from '../data/upload-topics.json';
import LOCALES from '../data/locales.json';
import appConstants from '../utils/constants/app';
import contentConstants from '../utils/constants/content';

const getAllLocales = ({ type = 'upload' } = {}) => {
  let filteredLocales = {};
  if (type === 'upload') filteredLocales = LOCALES;
  else if (type === 'app') {
    Object.keys(LOCALES).forEach((locale) => {
      if (LOCALES[locale].enabledForLocaleSwitch)
        filteredLocales[locale] = LOCALES[locale];
    });
  }
  return filteredLocales;
};

const getLocaleConfig = (locale) => {
  return LOCALES[locale] || LOCALES[appConstants.DEFAULT_LOCALE];
};

const getTagsByLocale = (locale = appConstants.DEFAULT_LOCALE) => {
  const tags = {};
  Object.keys(TAGS).forEach((tagKey) => {
    const tag = TAGS[tagKey];
    if (tag.locales.includes(locale)) {
      tags[tagKey] = tag;
    }
  });
  return tags;
};

const getTopicsByLocale = (locale = appConstants.DEFAULT_LOCALE) => {
  const topics = {};
  Object.keys(TOPICS).forEach((topicKey) => {
    const topic = TOPICS[topicKey];
    if (topic.locales.includes(locale)) {
      topics[topicKey] = topic;
    }
  });
  return topics;
};

const getTypesByLocale = (locale = appConstants.DEFAULT_LOCALE) => {
  const localeConfig = getLocaleConfig(locale);
  const types = {};
  if (localeConfig.uploadTypes?.length > 0) {
    Object.keys(contentConstants.UPLOAD_TYPES).forEach((typeKey) => {
      const uploadType = contentConstants.UPLOAD_TYPES[typeKey];
      if (localeConfig.uploadTypes.includes(uploadType)) {
        types[typeKey] = uploadType;
      }
    });
  }
  return types;
};

const getUserPreferredLocale = () => {
  const locales = getAllLocales();
  let preferredLocale = locales.en;
  if (typeof navigator !== 'undefined' && navigator && navigator.language) {
    const browserLocale = navigator.language.replace('-', '_');
    if (locales[browserLocale]) {
      preferredLocale = locales[browserLocale];
    } else {
      const [majorBrowserLocale] = browserLocale.split('_');
      if (locales[majorBrowserLocale]) {
        preferredLocale = locales[majorBrowserLocale];
      }
    }
  }
  return preferredLocale;
};

const getISOLocale = (locale) => {
  if (!locale) return null;
  const [language, country] = locale.split('-');
  if (!country) return locale;
  return `${language}_${country.toUpperCase()}`;
};

const getUrlLocale = (isoLocale) => {
  if (!isoLocale) return '';
  if (isoLocale === appConstants.DEFAULT_LOCALE) return '';
  const [language, country] = isoLocale.split('_');
  if (!country) return `${isoLocale}/`;
  return `${language}-${country.toLowerCase()}/`;
};

export {
  getAllLocales,
  getISOLocale,
  getLocaleConfig,
  getTagsByLocale,
  getTopicsByLocale,
  getTypesByLocale,
  getUrlLocale,
  getUserPreferredLocale,
};
