const config = require('../config');
const appConstants = require('./constants/app');
const ordinalNumberData = require('../data/ordinalNumber.json');

const isClient = () => {
  return typeof window === 'object';
};

function isProdMode() {
  return config.mode === appConstants.MODE_PRODUCTION;
}

function getCookie(cname) {
  const name = `${cname}=`;
  if (typeof document === 'undefined') {
    return null;
  }
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

function setCookie(value) {
  if (typeof document === 'undefined') {
    return;
  }
  document.cookie = value;
}

function generateQueryPath(pathname, query) {
  const path = `/${pathname}`;
  if (!query || typeof query !== 'object') {
    return path;
  }
  const queryParams = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (value) {
      queryParams.set(key, value);
    }
  });
  const queryString = queryParams.toString();
  if (queryString && queryString.length) {
    return `${path}?${queryParams.toString()}`;
  }
  return path;
}

function generateExternalUrlQueryPath(pathname, query) {
  const path = `${pathname}`;
  if (!query || typeof query !== 'object') {
    return path;
  }
  const queryParams = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (value) {
      queryParams.set(key, value);
    }
  });
  const queryString = queryParams.toString();
  if (queryString && queryString.length) {
    return `${path}?${queryParams.toString()}`;
  }
  return path;
}

function getSortOrder(prop) {
  return (a, b) => {
    if (a[prop] < b[prop]) {
      return -1;
    }
    if (a[prop] > b[prop]) {
      return 1;
    }
    return 0;
  };
}

function formatSecondsAsTime(currentTime) {
  if (typeof currentTime !== 'number') return '00:00';
  const hr = Math.floor(currentTime / 3600);
  let min = Math.floor((currentTime - hr * 3600) / 60);
  let sec = Math.floor(currentTime - hr * 3600 - min * 60);

  if (min < 10) {
    min = `0${min}`;
  }
  if (sec < 10) {
    sec = `0${sec}`;
  }

  if (hr > 0) {
    return `${hr}:${min}:${sec}`;
  }
  return `${min}:${sec}`;
}

function roundDecimals(value, decimals) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

function isNotificationSupported() {
  return 'Notification' in window;
}

function getPronouns(gender) {
  if (gender === 'male') return ['he', 'him', 'his'];
  if (gender === 'female') return ['she', 'her', 'her'];
  return ['he/she', 'him/her', 'his/her'];
}

// #gender replace gender pronoun in text
function replacePronounGender(text, gender) {
  if (!text) return '';
  const pronouns = getPronouns(gender);
  return text.replace('#{gender}', pronouns[1]);
}

function deepObjectEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i];
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepObjectEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

function formatNumber(num) {
  const number = parseInt(num, 10);
  if (Number.isNaN(number)) return 0;
  return number.toLocaleString();
}

function formatAmount(num) {
  const number = parseFloat(num);
  if (Number.isNaN(number)) return `$ 0`;
  return `$ ${number.toLocaleString()}`;
}

function isSameArrayValues(array1, array2) {
  return (
    array1.length === array2.length &&
    array1.every((array1Item) => array2.includes(array1Item))
  );
}

function convertToDollar(cent) {
  if (!cent) return 0;
  return cent / 100;
}

function convertToCent(dollar) {
  if (!dollar) return 0;
  return dollar * 100;
}

function roundToNearest10(number, func = 'round') {
  return Math[func](number / 10) * 10;
}

function cleanTitle(text) {
  let cleanText = text.replace(/  +/g, ' '); // replace multiple consecutive spaces in a string with a single space.
  cleanText = cleanText.replace(/"+/g, ''); //  remove double quotation marks
  cleanText = cleanText.replace(/[\s.,]+$/g, ''); // remove trailing spaces, commas, and periods from a string.
  return cleanText;
}

function getOrinalNumberString(number) {
  return ordinalNumberData[number];
}

module.exports = {
  isClient,
  isProdMode,
  isNotificationSupported,
  getCookie,
  generateQueryPath,
  getSortOrder,
  generateExternalUrlQueryPath,
  formatSecondsAsTime,
  roundDecimals,
  getPronouns,
  replacePronounGender,
  deepObjectEqual,
  formatNumber,
  formatAmount,
  isSameArrayValues,
  convertToDollar,
  convertToCent,
  getOrinalNumberString,
  roundToNearest10,
  cleanTitle,
  setCookie,
};
